import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosSearch } from "react-icons/io";
import { FaRegEyeSlash, FaRegMoneyBillAlt } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Donera
			</title>
			<meta name={"description"} content={"The Plugin for the Planet. Raise money for the best climate change projects globally."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/Chrome%20Screenshots-4.png?v=2023-12-04T02:54:39.004Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
		</Helmet>
		<Section background="--color-primary">
			<Override slot="SectionContent" />
			<Box
				display="flex"
				padding="10px 0 10px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15:57:09.725Z"
					display="block"
					width="180px"
					sm-width="150px"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 150px 0"
			background="--color-primary"
			md-padding="96px 0 0px 0"
			sm-padding="30px 0 0px 0"
			sm-flex-direction="row"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				margin="0px 64px 0 64px"
				sm-margin="0px 40px 0 40px"
				justify-content="center"
			/>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 40px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Fight Climate Change, 100% for Free.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
					sm-font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					Donera supercharges your homescreen to easily raise money for climate change. Every tab you open helps fund the best carbon offsets, advocacy groups, and R&D institutions.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="https://chrome.google.com/webstore/detail/donera/anlcfcmefdffefahfepkjbfgkajaacfg?hl=en-US"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-light"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-lightD2"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="70%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						target="_blank"
						sm-font="normal 500 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						Download on Chrome
					</Link>
					<Link
						href="https://docs.google.com/forms/d/1DgqYv68fR4fK1S8lGlczqsfvlJUzLezjd9mKt2S9J8w/edit"
						padding="10px 24px 10px 24px"
						color="--light"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 --fontFamily-sans"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-light"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="70%"
						hover-border-color="--color-lightD2"
						hover-color="--lightD2"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						hover-font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-font="normal 500 16px/1.5 --fontFamily-sans"
						target="_blank"
					>
						Download on Firefox
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				sm-order="-1"
				sm-flex-direction="row"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05:23:46.638Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="80%"
					sm-display="block"
					sm-transform="translateY(0px)"
					sm-margin="0px 0px 15px 0px"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Laptop%20Splash.webp?v=2022-06-03T05%3A23%3A46.638Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" color="--dark" id="#how-it-works">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" align-self="auto" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				color="--dark"
				flex="0 1 auto"
				align-self="flex-start"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="normal 900 50px/1.2 --fontFamily-sans"
					md-margin="0px 0px 30px 0px"
					align-self="auto"
					flex="0 0 auto"
					sm-text-align="center"
					sm-font="normal 900 36px/1.2 --fontFamily-sans"
				>
					30 Seconds.
					<br />
					2 Clicks.
					<br />
					1 Amazing Plugin.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					padding="20px 0px 20px 0px"
					font="--lead"
					sm-text-align="center"
					sm-font="17px sans-serif"
				>
					Donera is the easiest and most effective way to combat climate change. See the impact for yourself.
				</Text>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					order="0"
				/>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/wZzzgUS5gps" showOverlay modestbranding={false} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1" id="howitworks">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0.29) url(https://uploads.quarkly.io/62958497fedca4001e327b15/images/canopy%20overhead.webp?v=2022-06-03T07:15:16.390Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
				sm-background="rgba(0, 0, 0, 0.29) url(https://uploads.quarkly.io/62958497fedca4001e327b15/images/canopy%20overhead.webp?v=2022-06-03T02:12:40.288Z) 0% 0% /cover no-repeat scroll padding-box"
			>
				<Box
					width="70%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline1"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						border-color="--color-light"
					>
						How Donera Works.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Install.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Take 30 seconds to install Donera onto your favorite browser. It takes just 30 seconds, we promise.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Browse.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Open a new tab and see your beautiful new home screen. We'll place ads on each new tab.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Impact.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Each placed ad generates an ad impression and a little bit of revenue we send to our partner organizations.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-light" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
				sm-margin="0px 0px 20px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Transparent. Secure. 100% Effective.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				/>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					sm-padding="35px 45px 35px 45px"
				>
					<Icon
						category="io"
						icon={IoIosSearch}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
						sm-display="block"
						sm-margin="0px 0px 20px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						sm-font="normal 500 24px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-margin="0px 0px 12px 0px"
					>
						Radical Transparency.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Donera is the easiest installation you'll ever make. Trust us.{" "}
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					sm-padding="35px 45px 35px 45px"
				>
					<Icon
						category="fa"
						icon={FaRegEyeSlash}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
						sm-margin="0px 0px 20px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						sm-font="normal 500 24px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-margin="0px 0px 12px 0px"
					>
						100% Privacy, 100% of the Time.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Donera never tracks your search browsing history–ever. Total privacy, all the time.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					sm-padding="35px 45px 35px 45px"
				>
					<Icon
						category="fa"
						icon={FaRegMoneyBillAlt}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
						sm-margin="0px 0px 20px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						sm-font="normal 500 24px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-margin="0px 0px 12px 0px"
					>
						Real Money. Real Change.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Every new tab you open funds top climate projects across the world.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-primary" sm-padding="80px 0 50px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Beloved by Many.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="--headline1" sm-font="normal 900 60px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						“
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						sm-font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						An easy way to raise money for a vital cause with no downsides. Excited for this!
						<br />
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16:21:20.942Z"
							border-radius="50%"
							margin="0px 17px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/emily-review.jpeg?v=2022-05-31T16%3A21%3A20.942Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Emily Reinhold
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								Chrome User
							</Text>
							<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="5px 0px 0px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 0px 0px 15px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="--headline1" sm-font="normal 900 60px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						“
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						sm-font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						Turn your tabs into trees (and other things)! small way to help at no cost to you.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07:14:04.757Z"
							border-radius="50%"
							margin="0px 17px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/jack-review.jpeg?v=2022-06-03T07%3A14%3A04.757Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Jack Sullivan
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								Chrome User
							</Text>
							<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="5px 0px 0px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						font="--headline1"
						border-color="--color-light"
						sm-font="normal 900 60px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						“
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						sm-font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						Nice format, easy to use, and good cause!
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07:14:20.544Z"
							border-radius="50%"
							margin="0px 17px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/richard-review.jpeg?v=2022-06-03T07%3A14%3A20.544Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Richard Peng
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								Chrome User
							</Text>
							<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="5px 0px 0px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
						</Box>
					</Box>
				</Box>
				<Box
					lg-padding="0px 0px 0px 15px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="--headline1" sm-font="normal 900 60px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						“
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						sm-font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						This is so cool! I love saving the planet while I'm browsing!!
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07:13:45.585Z"
							border-radius="50%"
							margin="0px 17px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/dylan-review.jpeg?v=2022-06-03T07%3A13%3A45.585Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Dylan Erdalyi
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								Chrome User
							</Text>
							<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="5px 0px 0px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
						</Box>
					</Box>
				</Box>
			</Box>
			<Section padding="60px 0 24px 0">
				<Override slot="SectionContent" width="150px" />
				<Link
					href="https://chrome.google.com/webstore/detail/donera/anlcfcmefdffefahfepkjbfgkajaacfg?hl=en-US"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-light"
					text-decoration-line="initial"
					font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-lightD2"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					sm-width="100%"
					hover-transition="background-color 0.2s ease-in-out 0s"
					target="_blank"
					text-align="center"
					sm-font="normal 500 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					Download on Chrome
				</Link>
			</Section>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="40%"
				padding="15px 60px 15px 15px"
				lg-width="100%"
				lg-align-items="center"
				lg-display="flex"
				lg-justify-content="center"
				lg-flex-direction="column"
				lg-padding="15px 15px 15px 15px"
				lg-margin="0px 0px 30px 0px"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="--dark" sm-font="normal 900 36px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					By the Numbers.
				</Text>
				<Text font="--lead" color="--dark" lg-text-align="center" margin="0px 0px 0px 0px">
					Donera isn't just talk - we measure and track our impact.{" "}
				</Text>
			</Box>
			<Box width="60%" display="flex" lg-width="100%" sm-flex-wrap="wrap">
				<Box
					width="50%"
					padding="15px 15px 15px 15px"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
					lg-width="50%"
					sm-width="100%"
				>
					<Text margin="0px 0px 16px 0px" font="--headline1" color="--dark" sm-font="normal 900 34px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						50 Kg
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.2 --fontFamily-sans" color="--dark" text-align="center">
						of CO2 offset each year by the average user
					</Text>
				</Box>
				<Box
					width="50%"
					padding="15px 15px 15px 15px"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
					lg-width="50%"
					sm-width="100%"
				>
					<Text margin="0px 0px 16px 0px" font="--headline1" color="--dark" sm-font="normal 900 34px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						1142+
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.2 --fontFamily-sans" color="--dark" text-align="center">
						Tons offset by the Donera community
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Funding />
		<Structure cells-number-total="3" cells-number-group="3" sm-padding="10px 0px 50px 0px" padding="36px 0px 36px 0px">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override
					slot="Cell 1st"
					md-grid-column="1 / span 3"
					sm-grid-column="auto"
					display="block"
					align-self="end"
					justify-self="center"
					margin="0px 0px 8px 0px"
				/>
				<Override
					slot="Cell 2nd"
					md-grid-column="4 / span 3"
					sm-grid-column="auto"
					align-self="start"
					justify-self="center"
					margin="8px 0px 0px 0px"
				/>
				<Override slot="cell-0">
					<Text margin="0px 0px 0px 0px" text-align="center" font="--headline2" padding="0px 0px 20px 0px">
						Do Good.
						<br />
						Donera.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Link
						href="https://chrome.google.com/webstore/detail/donera/anlcfcmefdffefahfepkjbfgkajaacfg?hl=en-US"
						color="--light"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="#4679e3"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						target="_blank"
						sm-font="normal 500 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						background="--color-primary"
						hover-color="--light"
						padding="12px 24px 12px 24px"
					>
						Download on Chrome
					</Link>
				</Override>
				<Override slot="cell-2">
					<Link
						href="https://docs.google.com/forms/d/1DgqYv68fR4fK1S8lGlczqsfvlJUzLezjd9mKt2S9J8w/edit"
						padding="10px 24px 10px 24px"
						color="--primary"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 --fontFamily-sans"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-lightD2"
						hover-color="--lightD2"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						hover-font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-font="normal 500 16px/1.5 --fontFamily-sans"
						target="_blank"
					>
						Download on Firefox
					</Link>
				</Override>
				{"        "}{"        "}{"        "}{"    "}
			</Override>
		</Structure>
		<Components.DoneraFooter>
			<Override slot="text1" color="--light" text-align="left" font="normal 500 18px/1.2 --fontFamily-sans">
				hi@givedonera.com
			</Override>
			<Override slot="text3" font="normal 500 18px/1.2 --fontFamily-sans">
				San Francisco, CA
			</Override>
		</Components.DoneraFooter>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62958497fedca4001e327b13"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});